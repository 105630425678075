@import '../../../../scss/theme-bootstrap';

.tout-carousel-formatter {
  position: relative;
  width: 100%;
  max-width: $max-width-xlarge;
  margin: auto;
  &.padding-default-wrapper {
    padding: 30px 0;
    @include breakpoint($medium-up) {
      padding: 40px 20px;
    }
    .tout-block__wrapper-pad-default {
      padding-bottom: 0;
    }
  }
  &.tout-carousel-formatter--equal-height {
    .slick-track {
      display: flex !important;
    }
    .slick-slide {
      height: inherit !important;
    }
  }
  .content-block__content-wrapper {
    transform: translateZ(0);
  }
  .slick-dots {
    bottom: 20px;
    li {
      width: 17px;
      margin: 0;
      button:before {
        font-size: 30px;
        color: $color-very-light-pink;
      }
    }
  }
  // https://github.com/kenwheeler/slick/issues/1961
  .slick-track,
  .slick-list {
    -webkit-backface-visibility: hidden;
  }
  .tout-carousel {
    display: none; // Prevent FOUC
    &.slick-initialized {
      display: block;
    }
    &.slick-slider {
      margin-bottom: 0;
    }
    .tout-carousel__slide.slick-slide {
      margin: 0;
    }
    &__slide {
      .tout-block__media-wrapper {
        padding-top: 0;
      }
      &.padding--bare {
        @include swap_direction(padding, 0 20px 0 0);
        @include breakpoint($medium-up) {
          padding: 0 20px;
        }
      }
    }
  }
  &--no-peeking[data-slides='1'] {
    .tout-carousel {
      overflow: hidden;
      display: flex;
      justify-content: flex-start;
      > .tout-carousel__slide {
        flex-shrink: 0;
        overflow: hidden;
        display: inline-block;
        width: 100%;
      }
      &.slick-initialized {
        display: block;
      }
    }
  }
  .slick-slide {
    @include breakpoint($landscape-up) {
      opacity: 0;
      transition: opacity 0.3s;
    }
  }
  &.tout-carousel-formatter--no-peeking {
    .tout-carousel__slide.slick-slide {
      &.padding--bare {
        padding: 0 20px;
        @include breakpoint($medium-up) {
          padding: 0 20px 20px;
        }
      }
    }
    .mobile-arrow-top-25 {
      .slick-prev,
      .slick-next {
        top: 25%;
        transform: translateY(-25%);
        @include breakpoint($landscape-up) {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  &.tout-carousel-formatter--peeking {
    .slick-list {
      @include breakpoint($medium-down) {
        padding: 0 20px;
        .tout-carousel-formatter[class$='padding-sides-small-'] &,
        .tout-carousel-formatter[class*='padding-sides-small-'] & {
          @include swap_direction(padding, 0 30px 0 0);
        }
      }
    }
    .mobile-arrow-top-25 {
      .slick-prev,
      .slick-next {
        top: 25%;
        transform: translateY(-25%);
        @include breakpoint($landscape-up) {
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .slick-prev {
        #{$ldirection}: 2.7rem;
        @include breakpoint($landscape-up) {
          #{$ldirection}: 0;
        }
      }
      .slick-next {
        #{$rdirection}: 2.7rem;
        @include breakpoint($landscape-up) {
          #{$rdirection}: 0;
        }
        &:before {
          #{$rdirection}: 0;
        }
      }
    }
  }
  &.tout-carousel-formatter--pc-peeking {
    @include breakpoint($landscape-up) {
      .slick-list {
        padding-#{$rdirection}: 30px;
      }
      .slick-prev {
        #{$ldirection}: 2%;
      }
      .slick-next {
        #{$rdirection}: 2%;
        &::before {
          #{$rdirection}: 0;
        }
      }
      .slick-slide {
        opacity: 1;
        padding: 0 25px;
      }
      .padding--bare {
        padding: 36px 0 40px;
      }
    }
  }
  &__content-above {
    &.tout-carousel-formatter__cta-beside {
      flex-direction: row;
      justify-content: space-between;
      width: auto;
    }
    .tout-carousel-formatter__header-wrapper {
      p {
        margin: 0;
      }
    }
  }
  &.tout-carousel-formatter--pc-borders {
    @include breakpoint($medium-up) {
      .tout-carousel__slide.slick-slide {
        border-#{$ldirection}: 1px solid $color-gray-op15;
        &:first-child,
        &.slick-active-first {
          border-color: transparent;
        }
      }
    }
  }
  .slick-active {
    @include breakpoint($landscape-up) {
      opacity: 1;
    }
  }
  .carousel-controls {
    .slick-prev,
    .slick-next {
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      background: $color-black;
      white-space: normal;
      @include breakpoint($medium-up) {
        width: 80px;
        height: 80px;
      }
      &:before {
        content: '';
        position: relative;
        #{$ldirection}: 0;
        width: 100%;
        height: 14px;
        filter: brightness(1) invert(1);
      }
      &.slick-disabled {
        visibility: hidden;
      }
    }
    .slick-prev {
      #{$ldirection}: 0;
      &:before {
        background: url('#{$base-theme-path}svg-icons/src/icon--dropdown.svg') center no-repeat;
        transform: rotate(90deg);
      }
    }
    .slick-next {
      #{$rdirection}: 0;
      &:before {
        background: url('#{$base-theme-path}svg-icons/src/icon--dropdown.svg') center no-repeat;
        transform: rotate(-90deg);
      }
    }
  }
  .carousel-dots {
    .slick-dots {
      position: relative;
      bottom: 0;
      padding: 20px 0;
      li {
        width: auto;
        height: auto;
        margin: 0;
      }
      button {
        @include swap_direction(margin, 1px 8px 0 0);
        display: inline-block;
        width: 12px;
        height: 1px;
        padding: 0;
        background-color: $color-gray-op65;
        &:before {
          display: none;
        }
        .dark-background & {
          background-color: $color-white-op50;
        }
      }
      .slick-active {
        button {
          height: 2px;
          margin-top: 0;
          background-color: $color-core-black;
          .dark-background & {
            background-color: $color-white;
          }
        }
      }
    }
  }
}
